@keyframes loader {
  0% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

.stub {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
}

.main {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.logo {
  width: 192px;
  height: 80px;
  object-fit: contain;
}

.loader {
  position: relative;
  width: 192px;
  height: 4px;
  border-radius: 2px;
  background: var(--color-neutral-200);
  overflow: hidden;
}

.loader:after {
  content: '';
  position: absolute;
  width: 100%;
  background-color: var(--color-primary-500);
  transform: translateZ(0);
  animation: loader 20s cubic-bezier(0, 0, 0, 1) infinite;
  z-index: 1;
  inset: 0;
}

.footer {
  padding: 32px;
  font-weight: 400;
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1;
  text-align: center;
  color: var(--color-neutral-600);
}
